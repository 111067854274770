<template>
    <span>
		<a href="javascript:;" @click.prevent="showModal()">
			<slot name="button"></slot>
		</a>

		<b-modal :id="'AddListenerModal' + idBid" size="md" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">Добавить слушателя на программу {{name}}</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('AddListenerModal' + idBid)"></a>
			</template>

			<table class="table table-no-border-last align-middle mb-0">
				<tbody>
					<tr>
					  <td>Новый слушатель</td>
					  <td class="pe-0 text-end">
						  <router-link :to="`/orders/${idOrder}/add-listener/?ID_BID=${idBid}&ID_PROGRAM=${idProgram}`" class="btn btn-success fs-12px w-100">
							Добавить
						  </router-link>
					  </td>
				  	</tr>
				</tbody>
			</table>
			<table class="table table-no-border-last align-middle mb-0">
              <tbody>
				<tr v-for="listener in items" :key="'listener_' + listener.info?.id" v-if="listener.info">
                  <td>{{[listener.info?.surname, listener.info?.name, listener.info?.patronymic].join(' ')}}</td>
                  <td class="text-nowrap">
					  {{$formatPhone(listener.info?.phone)}}<br />
					  {{listener.info?.email}}
				  </td>
                  <td class="w-100px">
                    <div class="form-check d-flex align-items-center justify-content-center">
                      <input type="checkbox"
							 v-model="listener.checked"
							 :id="'table_checkbox_' + listener.info?.id"
							 class="form-check-input form-check-input-bordered">
                    </div>
                  </td>
                  <td class="w-150px pe-0 text-end">
                    <a href="javascript:void(0)" @click="addSelectedListener(listener)" class="btn btn-primary w-150px fs-12px px-5px">Выбрать</a>
                  </td>
                </tr>

              </tbody>
            </table>

			<div slot="modal-footer" class="d-flex justify-content-center w-100 gap-3">
				<button type="button" @click="addSelectedListeners" class="btn btn-primary btn-lg">
					Добавить
				</button>
				<!--<button type="button" @click="" class="btn btn-success btn-lg flex-1">
					Импорт из Excel
				</button>-->
			</div>
		</b-modal>
    </span>
</template>

<script>
  import { mapGetters } from 'vuex'
  import IconPlus from '../icons/IconPlus'
  import axios from 'axios'
  import appOptions from '../../config/options'

  export default {
    name: 'AddListenerModal',
    components: { IconPlus },
    props: ['button', 'name', 'idOrder', 'idBid', 'idProgram'],
    data() {
      return {
        items: [],
      }
    },
    computed: {
      listeners() {
        return this.idOrder ? this.$store.getters['listeners/items'](this.idOrder) : []
      },
      token() {
        return this.$store.getters['auth/token']
      },
    },
    watch: {
      listeners(newVal, oldVal) {
        this.items = newVal.map(item => {
          return {
            ...item,
            checked: false,
          }
        })
      },
    },
    mounted() {
      this.items = this.listeners.map(item => {
        return {
          ...item,
          checked: false,
        }
      })
    },
    methods: {
      showModal() {
        this.$bvModal.show('AddListenerModal' + this.idBid)
      },
      addSelectedListener(listener) {
        this.sendListeners([listener.listener_id])
      },
      addSelectedListeners() {
        //this.items.filter(item => item.checked)
        this.sendListeners(this.items.filter(item => item.checked).map(item => item.listener_id))
      },
	  sendListeners(listeners) {
        axios({
          url: appOptions.api + '/orders/bids/joinListenerInBid/',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
          data: {
            listener_ids: listeners,
            bid_id: this.idBid
		  },
        })
          .then(res => res.data)
          .then(res => {
            if (res) {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Слушатель добавлен!`,
                type: 'success',
              })
              this.$store.dispatch('orders/fetchOrderItem', this.$route.params.id)
              this.$bvModal.hide('AddListenerModal' + this.idBid)
            }
          })
	  }
    },
  }
</script>
