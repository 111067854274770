<template>
	<div>
		<div v-if="order">
			<!-- BEGIN breadcrumb -->
			<ol class="breadcrumb d-none d-md-flex">
				<li class="breadcrumb-item">
					<router-link to="/">Главная</router-link>
				</li>
				<li class="breadcrumb-item">
					<router-link to="/">Личный кабинет</router-link>
				</li>
				<li class="breadcrumb-item active">Заказы</li>
			</ol>
			<!-- END breadcrumb -->
			<div class="mt-25px mb-25px">
				<router-link to="/orders/" class="d-inline-flex align-items-center text-decoration-none text-dark">
					<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-10px">
						<path d="M6.79608 10.3333C6.72165 10.4043 6.66239 10.4896 6.62191 10.5841C6.58142 10.6787 6.56055 10.7804 6.56055 10.8833C6.56055 10.9861 6.58142 11.0879 6.62191 11.1824C6.66239 11.277 6.72165 11.3623 6.79608 11.4333L14.6528 19.29L15.7528 18.19L8.44608 10.8833L15.7528 3.57562L14.6528 2.47562L6.79608 10.3333Z" fill="#2B2A29" />
					</svg>
					Назад
				</router-link>
			</div>
			<div class="mw-1200px pb-30px">
				<!-- BEGIN page-header -->
				<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">

					<div class="d-flex flex-wrap flex-row align-items-end justify-content-between">
						<h1 class="page-header">Заказ №{{order.id}} <span class="ms-2 fs-14px fw-400" v-if="order.date_created">от {{order.date_created}}</span></h1>
					</div>

					<div class="d-flex align-items-center mb-3 mt-md-0" v-if="manager">
						<span>Ваш менеджер:</span>
						<b-dropdown variant="link" right>
							<template #button-content>
								<span>{{[manager.surname, manager.name, manager.patronymic].join(' ')}}</span>
								<IconChevronDown class="ms-2 dropdown-chevron" />
							</template>
							<b-dropdown-text>
								<div class="d-flex mb-3 w-300px">
									<div class="w-35px h-35px rounded-pill overflow-hidden">
										<img src="/assets/img/user/user.svg" alt="" class="mw-100">
									</div>
									<div class="flex-1 ps-3">
										<p class="fw-400 mb-0">Ваш менеджер</p>
										<p class="mb-2 fw-600 fs-5">{{[manager.surname, manager.name, manager.patronymic].join(' ')}}</p>
										<p class="mb-1 fw-400" v-if="manager.phone"><a :href="'tel:' + manager.phone">{{$formatPhone(manager.phone)}}</a></p>
										<p class="mb-3 fw-400" v-if="manager.email"><a :href="'mailto:' + manager.email">{{manager.email}}</a></p>
										<!--<p class="mb-0">
											<a href="javascript:;" class="btn btn-primary">Написать в чат</a>
										</p>-->
									</div>
								</div>
							</b-dropdown-text>
						</b-dropdown>
					</div>
				</div>
				<!-- END page-header -->

				<div class="border border-2 border-radius-lg border-gray px-24px py-18px mb-24px">
					<div class="d-flex justify-content-between align-items-center" :class="payment ? 'mb-12px' : ''">
						<div class="fs-24px fw-500">Реквизиты плательщика</div>
						<PayerDetailsEditModal :data="payment" :id="order.id" v-if="payment">
							<template slot="button">
								<a href="javascript:;">
									<IconPen />
								</a>
							</template>
						</PayerDetailsEditModal>
						<div v-else>
							<PayerDetailsEditModal :id="order.id">
								<template slot="button">
									<a href="javascript:void(0)" class="d-inline-flex align-items-center fw-500 mt-32px text-decoration-none text-dark">
									<span class="d-flex align-items-center justify-content-center w-50px h-50px border-radius-round bg-gray-back me-12px">
										<IconPlus />
									</span>
										Добавить
									</a>
								</template>
							</PayerDetailsEditModal>
						</div>
					</div>
					<div class="d-grid columns-grid" v-if="payment && (payment.type_face === 'UR' || payment.type_face === 'IP')">
						<div class="columns-item" v-if="payment.abbreviation">{{payment.abbreviation}}</div>
						<div class="columns-item" v-if="payment.inn">ИНН: {{payment.inn}}</div>
						<div class="columns-item" v-if="payment.kpp">КПП: {{payment.kpp}}</div>
						<div class="columns-item" v-if="payment.ogrn">ОГРН: {{payment.ogrn}}</div>
						<div class="columns-item" v-if="payment.city">Город: {{payment.city}}</div>
						<div class="columns-item" v-if="payment.registration_address">Адрес: {{payment.registration_address}}</div>
					</div>
					<div v-if="payment && payment.type_face === 'FIZ'">
						<div class="columns-item" v-if="payment.name">{{[payment.surname, payment.name, payment.patronymic].join(' ')}}</div>
						<div class="columns-item" v-if="payment.snils">СНИЛС: {{payment.snils}}</div>
					</div>
				</div>

				<div class="border border-2 border-radius-lg border-gray px-24px pt-18px pb-24px mb-24px" v-if="bids && bids.length">
					<div class="fs-24px fw-500 mb-16px">Состав заказа</div>

					<div :class="'order-item' + (index !== 0 ? ' mt-4' : '') " v-for="(item, index) in bids" :key="'bids_' + item.id">

						<div class="bg-success py-8px px-16px fs-14px lh-main text-white mb-16px border-radius-lg" v-if="item.programm?.type_educational_programs">{{item.programm.type_educational_programs.name}}</div>

						<div class="d-flex justify-content-between align-items-center flex-wrap mb-8px">
							<div class="d-flex flex-wrap align-items-center">
								<span class="me-24px fs-18px fw-600">Заявка {{index + 1}}. Программа "{{item.programm?.name}}"</span>
								<span :class="'border-radius-xl px-12px py-7px  ' + status(item.status.code)" v-if="item.status">Статус: {{item.status.name}}</span>
							</div>
							<div class="d-flex align-items-center mt-16px mt-md-0">
								<AddListenerModal :id-order="$route.params.id" :id-program="item.program_id" :id-bid="item.id">
									<template slot="button">
										<IconAddUser class="ms-12px" />
									</template>
								</AddListenerModal>
								<DeleteProgramModal :id="item.program_id" :id-bid="item.id" :id-order="$route.params.id">
									<template slot="button">
										<IconTrash class="ms-12px" />
									</template>
								</DeleteProgramModal>
							</div>
						</div>

						<div class="table-responsive-md" v-if="item.listeners && item.listeners.length">
							<table class="table mb-0 align-middle">
								<thead>
								<tr>
									<th>Слушатель</th>
									<th>Контактные данные</th>
									<th>Блоки<br />программы</th>
									<th>Есть<br />ВО/СПО</th>
									<th>Объем<br />часов</th>
									<th>Вид<br />программы</th>
									<th>Форма<br />обучения</th>
									<th>Вид документа</th>
									<th>Стоимость</th>
									<th class="w-200px"></th>
								</tr>
								</thead>
								<tbody>
								<tr v-for="listener in item.listeners" :key="'listener_' + item.id + '_' + listener.id">
									<td>
										{{[listener.info?.surname, listener.info?.name, listener.info?.patronymic].join(' ')}}
									</td>
									<td>
										{{$formatPhone(listener.info?.phone)}}<br />
										{{listener.info?.email}}
									</td>
									<td>
										<div v-for="block in listener.configs?.blocks" v-if="listener.configs?.blocks" :key="item.id + '_block_' + block.id">
											{{block.name}}
										</div>
									</td>
									<td>
										{{listener.info?.avalible_vo_spo ? 'Да' : 'Нет'}}
									</td>
									<td>
										{{listener.configs?.count_clock}}
									</td>
									<td>
										{{listener.configs?.programm_type}}
									</td>
									<td>
										{{listener.configs?.form_education}}
									</td>
									<td>
										{{listener.configs?.type_document}}
									</td>
									<td>
										{{$formatPrice(listener.configs?.price)}}
									</td>
									<td nowrap="nowrap">
										<router-link :to="`/orders/${$route.params?.id}/edit-listener/?ID_BID=${item.id}&ID_PROGRAM=${item.program_id}&ID_LISTENER=${listener.id}`">
											<IconPen />
										</router-link>
										<DeleteListenerModal
												:id-order="$route.params?.id"
												:id="listener.id"
												:program-name="item.programm?.name"
												:listener-fio="[listener.info?.surname, listener.info?.name, listener.info?.patronymic].join(' ')">
											<template slot="button">
												<IconTrash class="ms-12px" />
											</template>
										</DeleteListenerModal>
										<a href="javascript:;" @click.prevent="sendAccesses(listener.id)" class="btn btn-sm btn-primary ms-12px py-5px px-7px">Отправить доступы</a>
									</td>
								</tr>
								</tbody>
							</table>
						</div>

						<template v-if="paymentsByOrderBids[item.id]">

							<div class="fs-18px fw-600 mt-16px mb-16px" :id="'order_payment_block_' + item.id">Формат оплаты</div>

							<div class="d-flex flex-wrap mb-n3 w-100">
								<div class="me-96px mb-12px">
									<div class="form-check mb-8px">
										<input type="radio" :name="'order_payment_'+item.id" :id="'order_payment_'+item.id+'_1'" class="form-check-input" :value="1" v-model="paymentsByOrderBids[item.id].order_payment">
										<label :for="'order_payment_'+item.id+'_1'" class="form-check-label"> 100% предоплата</label>
									</div>
									<div class="form-check mb-8px">
										<input type="radio" :name="'order_payment_'+item.id" :id="'order_payment_'+item.id+'_2'" class="form-check-input" :value="2" v-model="paymentsByOrderBids[item.id].order_payment">
										<label :for="'order_payment_'+item.id+'_2'" class="form-check-label"> Частями</label>
									</div>
									<div class="form-check mb-8px">
										<input type="radio" :name="'order_payment_'+item.id" :id="'order_payment_'+item.id+'_3'" class="form-check-input" :value="3" v-model="paymentsByOrderBids[item.id].order_payment">
										<label :for="'order_payment_'+item.id+'_3'" class="form-check-label"> Постоплата</label>
									</div>
								</div>
								<div class="w-100 w-md-400px me-96px mb-20px position-relative" v-if="paymentsByOrderBids[item.id].order_payment.toString() === '2'">
									<div class="mb-8px">
										Введите удобный для вас график оплаты. Первая и вторая части оплаты не могут быть менее 30% каждая
									</div>

									<div class="d-flex">
										<div class="me-24px">
											<label class="fs-12px m-4px">1я часть</label>
											<input type="text" :class="'form-control w-70px' + (paymentsByOrderBids[item.id].second_error ? ' is-invalid' : '')" v-model="paymentsByOrderBids[item.id].first" @focus="paymentsByOrderBids[item.id].second_error = false" />
										</div>
										<div class="me-24px">
											<label class="fs-12px m-4px">2я часть</label>
											<input type="text" :class="'form-control w-70px' + (paymentsByOrderBids[item.id].second_error ? ' is-invalid' : '')" v-model="paymentsByOrderBids[item.id].second" @focus="paymentsByOrderBids[item.id].second_error = false" />
										</div>
										<div>
											<label class="fs-12px m-4px">3я часть</label>
											<input type="text" :class="'form-control w-70px' + (paymentsByOrderBids[item.id].second_error ? ' is-invalid' : '')" v-model="paymentsByOrderBids[item.id].third" @focus="paymentsByOrderBids[item.id].second_error = false" />
										</div>
									</div>

									<div v-if="paymentsByOrderBids[item.id].second_error" class="invalid-tooltip">Заполните поля</div>
								</div>

								<div class="d-flex flex-column align-items-end mb-12px" v-if="item.price">
									<div>
										Итого:
										<div class="fw-500">{{$formatPrice(item.price)}}</div>
									</div>
								</div>
							</div>
						</template>

					</div>
				</div>

				<a href="javascript:void(0)" @click="$router.push({ path: pathToAddProgram })" class="d-inline-flex align-items-center fw-500 mt-32px text-decoration-none text-dark">
					<span class="d-flex align-items-center justify-content-center w-50px h-50px border-radius-round bg-gray-back me-12px">
						<IconPlus />
					</span>
					Добавить программу
				</a>

				<div class="d-flex align-items-end flex-column mt-24px text-end" v-if="loyalityProgram && priceDiscont">
					<div class="text-muted mb-12px">Итого по заказу</div>
					<div class="fs-24px fw-600">{{$formatPrice(price)}} ₽</div>
				</div>

				<div class="border border-2 border-radius-lg border-gray px-24px py-18px mb-24px" v-if="loyalityProgram && priceDiscont">
					<div class="d-flex justify-content-between align-items-center">
						<div>
							<div class="fs-18px fw-600 mb-8px">Программа лояльности</div>
							{{loyalityProgram.name}} - {{loyalityProgram.percent}}%
						</div>
						<div class="col-auto col-md-4 col-lg-2">
							<div class="text-muted mb-12px">Общая стоимость</div>
							<div class="fs-24px fw-600 d-flex align-items-start">
								<span class="fs-14px text-overline text-dark-700 me-4px">{{$formatPrice(price)}} ₽</span>
								{{$formatPrice(priceDiscont)}} ₽
							</div>
						</div>
					</div>
				</div>

				<div class="mt-18px">
					<div class="d-flex justify-content-between align-items-center" v-if="priceDiscont || price">
						<div class="ms-auto text-end">
							<div class="text-muted mb-12px">Итого</div>
							<div class="fs-24px fw-600">
								{{$formatPrice(priceDiscont || price)}} ₽
							</div>
						</div>
					</div>
					<div class="text-center mt-32px">
						<a href="javascript:void(0)" @click.prevent="checkSubmit" class="btn btn-primary px-45px py-10px">
							Отправить на проверку
						</a>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div v-if="loaded && !order">
				Заказ не найден
			</div>
		</div>


		<notifications group="center" position="top center" :duration="10000000000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<a class="title">
						{{props.item.title}}
					</a>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>
  import PayerDetailsEditModal from '../../components/modal/PayerDetailsEditModal'
  import IconAddUser from '../../components/icons/IconAddUser'
  import IconChevronDown from '../../components/icons/IconChevronDown'
  import IconPen from '../../components/icons/IconPen'
  import IconTrash from '../../components/icons/IconTrash'
  import IconPlus from '../../components/icons/IconPlus'
  import AddListenerModal from '../../components/modal/AddListenerModal'
  import DeleteListenerModal from '../../components/modal/DeleteListenerModal'
  import DeleteProgramModal from '../../components/modal/DeleteProgramModal'
  import axios from 'axios'
  import appOptions from '../../config/options'
  import uniqid from 'uniqid'
  import { scroller } from 'vue-scrollto/src/scrollTo'

  const PAYMENT_FORM = {
    order_payment: 1,
    first: '',
    second: '',
    third: '',
    second_error: false,
	name: ''
  }

  export default {
    name: 'OrderDetail',
    components: { DeleteProgramModal, DeleteListenerModal, AddListenerModal, IconPlus, IconTrash, IconPen, IconChevronDown, IconAddUser, PayerDetailsEditModal },
    data() {
      return {
        loaded: false,
        paymentsByOrderBids: {},
      }
    },
    watch: {
      order(newVal, oldVal) {
        let bids = newVal?.bids || []
        if (bids && bids.length && JSON.stringify(oldVal.bids) !== JSON.stringify(bids)) {
          let paymentsByOrderBids = JSON.parse(JSON.stringify(this.paymentsByOrderBids))
          for (let i = 0, l = bids.length; i < l; i++) {
            let id = bids[i].id
            if (!paymentsByOrderBids[id]) {
              paymentsByOrderBids[id] = JSON.parse(JSON.stringify(PAYMENT_FORM))
            }
            let payment = bids[i].payment
            if (payment && payment.length) {
              let paymentId = null
              for (let i = 0, l = payment.length; i < l; i++) {
                if (i === 0 && payment[i].payment_method_id === 2) {
                  paymentsByOrderBids[id].first = payment[i].percent || paymentsByOrderBids[id].first
                }
                if (i === 1 && payment[i].payment_method_id === 2) {
                  paymentsByOrderBids[id].second = payment[i].percent || paymentsByOrderBids[id].second
                }
                if (i === 2 && payment[i].payment_method_id === 2) {
                  paymentsByOrderBids[id].third = payment[i].percent || paymentsByOrderBids[id].third
                }
                paymentId = payment[i].payment_method_id
              }
              if (paymentId) {
                paymentsByOrderBids[id].order_payment = paymentId || paymentsByOrderBids[id].order_payment
              }
            }
            paymentsByOrderBids[id].name = `Заявка ${i + 1}`
          }
          this.paymentsByOrderBids = paymentsByOrderBids
          console.log('CHANGE')
        }
      },
    },
    computed: {
      token() {
        return this.$store.getters['auth/token']
      },
      bids() {
        let bids = this.order?.bids
        if (bids && bids.length) {
          for (let key = 0, lkey = bids.length; key < lkey; key++) {
            let price = 0
            let listeners = bids[key].listeners
            if (listeners && listeners.length) {
              for (let i = 0, l = listeners.length; i < l; i++) {
                price += listeners[i].configs?.price
              }
            }
            bids[key].price = price
          }
        }
        return bids
      },
      payment() {
        return this.order?.payment
      },
      manager() {
        return this.order?.manager
      },
      loyalityProgram() {
        return this.order?.loyalityProgram
      },
      priceDiscont() {
        let priceDiscont = 0
        if (this.loyalityProgram && this.price) {
          let percent = Number(this.loyalityProgram.percent)
          priceDiscont = this.price - (this.price / 100 * percent)
        }
        return priceDiscont
      },
      price() {
        let price = 0
        let bids = this.order?.bids
        if (bids && bids.length) {
          for (let key = 0, lkey = bids.length; key < lkey; key++) {
            let listeners = bids[key].listeners
            if (listeners && listeners.length) {
              for (let i = 0, l = listeners.length; i < l; i++) {
                price += listeners[i].configs?.price
              }
            }
          }
        }

        return price
      },
      order() {
        return this.$route.params.id ? this.$store.getters['orders/itemById'](this.$route.params.id) : null
      },
      pathToAddProgram() {
        return this.$route.params.id ? `/orders/${this.$route.params.id}/add-program/` : ''
      },
    },
    created() {
      this.$store.dispatch('orders/fetchOrderItem', this.$route.params.id)
        .then((res) => {
          this.loaded = true
          if (res.request?.status === 401) {
            this.$store.dispatch('auth/logout')
            this.$router.push({ path: '/login' })
          }
        })
      this.$store.dispatch('listeners/fetchListenerItems', { idOrder: this.$route.params.id })
    },
    methods: {
      status(code) {
        switch (code) {
          case 'payed':
            return 'bg-yellow text-black'
          case 'polychena':
            return 'bg-yellow text-dark'
        }
      },
      sendAccesses(listener_id) {
        if (!listener_id) return
        axios({
          url: appOptions.api + '/orders/getAccessesByListener/',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
          data: {
            listener_id: listener_id,
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res.data && res.data.user_id) {
              this.$notify({
                group: 'center',
                title: ``,
                text: res.data.message,
                type: 'success',
              })
            }
          })
      },
      checkSubmit() {
        let isValid = true
		let errors = []
		console.log(this.paymentsByOrderBids)
        for (let key in this.paymentsByOrderBids) {
          if (this.paymentsByOrderBids.hasOwnProperty(key) && this.paymentsByOrderBids[key].order_payment.toString() === '2') {
            this.paymentsByOrderBids[key].second_error = false
            if (!this.paymentsByOrderBids[key].first || !this.paymentsByOrderBids[key].second || !this.paymentsByOrderBids[key].third) {
              this.paymentsByOrderBids[key].second_error = true
              isValid = false
              const scrollTo = scroller()
              scrollTo('#order_payment_block_' + key)
            }
            if (Number(this.paymentsByOrderBids[key].first) < 30 || Number(this.paymentsByOrderBids[key].second) < 30) {
             errors.push(`${this.paymentsByOrderBids[key].name}. При выборе оплаты по частям значения в первых двух полях должны быть не менее 30 процентов`)
              isValid = false
			}
            if (Number(this.paymentsByOrderBids[key].first) + Number(this.paymentsByOrderBids[key].second) + Number(this.paymentsByOrderBids[key].third) !== 100) {
              errors.push(`${this.paymentsByOrderBids[key].name}. При выборе оплаты по частям общая сумма частей должна быть равна 100 процентам`)
              isValid = false
			}
          }
        }

        if (errors.length) {
          this.$notify({
            group: 'center',
            title: ``,
            text: errors.join('<br/>'),
            type: 'error',
          })
		}

        if (isValid) {
          let data = {
            order_id: this.$route.params.id,
            payment_method_id: {},
          }

          Object.keys(this.paymentsByOrderBids).forEach((key, index) => {
            let item = this.paymentsByOrderBids[key]
            let _id = key
            data.payment_method_id[_id] = item.order_payment
            if (item.order_payment.toString() === '2') {
              if (!data.percent) data.percent = {}
              data.percent[_id] = [item.first, item.second, item.third]
            }
          })

          axios({
            url: appOptions.api + '/orders/sendOrderVerification/',
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${this.token}`,
            },
            data: data,
          })
            .then(res => res.data)
            .then(res => {
              if (res.data && res.data.order_id) {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: res.data.message,
                  type: 'success',
                })
              }
            })
        }
      },
    },
  }
</script>
