<template>
	<div>
		<!-- BEGIN login -->
		<div class="login login-v2 fw-bold">
			<!-- BEGIN login-cover -->
			<div class="login-cover">
				<div class="login-cover-img" v-bind:style="{ backgroundImage: 'url('+ bg.activeImg +')' }"></div>
				<div class="login-cover-bg"></div>
			</div>
			<!-- END login-cover -->

			<!-- BEGIN login-container -->
			<div class="login-container">
				<!-- BEGIN login-header -->
				<div class="login-header justify-content-center">
					<!--<div class="brand">
						<div class="d-flex align-items-center">
							<img class="logo-img" src="/assets/img/logo/pish-logo.svg" />
						</div>
					</div>-->
					<div class="fs-1 lh-1 fw-bold text-center">Восстановление доступа</div>
				</div>
				<!-- END login-header -->

				<p>Укажите эл. почту ранее созданной учетной записи. На эл. адрес, привязанный к вашему аккаунту, придет инструкция для сброса пароля.</p>
				<!-- BEGIN login-content -->
				<div class="login-content">
					<ValidationObserver
							tag="form"
							ref="form_forgot_pass_observer"
							@submit.prevent="checkForm()"
							v-slot="{ invalid }"
							method="POST">

						<ValidationProvider
								tag="div"
								class="form-floating mb-20px"
								ref="form_forgot_pass_email"
								rules="required|email"
								name="E-mail"
								v-slot="{ errors }">

							<input
									type="text"
									class="form-control fs-14px h-40px"
									:class="errors.length ? 'is-invalid' : ''"
									placeholder=""
									name="form_forgot_pass_email"
									id="form_forgot_pass_email"
									@input="checkValidate('email')"
									@change="checkValidate('email')"
									v-model="form['email']" />

							<label for="form_forgot_pass_email" class="d-flex align-items-center text-gray-600 fs-14px">E-mail</label>

						</ValidationProvider>

						<button type="submit" class="btn btn-success d-block w-100 h-45px fs-14px btn-lg">Отправить</button>

						<div class="text-center mt-4">
							<RouterLink to="/login">К авторизации</RouterLink>
						</div>

						<!--<div class="text-gray-500 fs-14px">
							Not a member yet? Click <a href="javascript:;">here</a> to register.
						</div>-->

					</ValidationObserver>
				</div>
				<!-- END login-content -->
			</div>
			<!-- END login-container -->
		</div>
		<!-- END login -->
		<notifications group="center" position="center top" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<a class="title">
						{{props.item.title}}
					</a>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>
  import AppOptions from '../config/options.js'
  import axios from 'axios'
  import appOptions from '../config/options'
  import LogoImg from '../../public/assets/img/login-bg/login-bg.jpg'

  export default {
    created() {
      AppOptions.appEmpty = true
    },
    beforeRouteLeave(to, from, next) {
      AppOptions.appEmpty = false
      next()
    },
    data() {
      return {
        bg: {
          activeImg: LogoImg,
        },
        form: {
          email: '',
        },
      }
    },
    methods: {
      checkValidate: function (name) {
        const provider = this.$refs[name]
        if (provider && provider.validate) {
          provider.validate()
        }
      },
      checkForm: async function () {
        const isValid = await this.$refs.form_forgot_pass_observer.validate()
        if (isValid) {
          const formData = new FormData()
          formData.append('email', this.form.email)
          axios({
            url: appOptions.api + '/auth/forgotpassword/',
            method: 'POST',
            headers: {
              'Accept': 'application/json',
            },
            data: formData,
          })
            .then(res => {
              res = res.data?.data
              if (res.message) {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: res.message,
                  type: 'success',
                })
              }
            })
            .catch(err => {
              err = err.response?.data
              if (err.message) {
                this.$notify({
                  group: 'center',
                  title: `Ошибка!`,
                  text: err.message,
                  type: 'error',
                })
              }
            })
        }
      },
    },
  }
</script>
