<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb d-none d-md-flex">
			<li class="breadcrumb-item">
				<router-link to="/">Главная</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link to="/">Личный кабинет</router-link>
			</li>
			<li class="breadcrumb-item active">Заказы</li>
		</ol>
		<!-- END breadcrumb -->
		<div class="mt-25px mb-25px">
			<a href="#" @click.prevent="hasHistory() ? $router.go(-1) : $router.push('/')" class="d-inline-flex align-items-center text-decoration-none text-dark">
				<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-10px">
					<path d="M6.79608 10.3333C6.72165 10.4043 6.66239 10.4896 6.62191 10.5841C6.58142 10.6787 6.56055 10.7804 6.56055 10.8833C6.56055 10.9861 6.58142 11.0879 6.62191 11.1824C6.66239 11.277 6.72165 11.3623 6.79608 11.4333L14.6528 19.29L15.7528 18.19L8.44608 10.8833L15.7528 3.57562L14.6528 2.47562L6.79608 10.3333Z" fill="#2B2A29" />
				</svg>
				Назад
			</a>
		</div>
		<div class="mw-1200px">
			<!-- BEGIN page-header -->
			<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
				<h1 class="page-header">{{idListener ? 'Редактировать слушателя' : 'Добавить слушателя'}}</h1>
			</div>
			<!-- END page-header -->
			<ValidationObserver tag="form"
								@submit.prevent="checkSubmit"
								ref="observer-form-listener"
								v-slot="{ invalid }"
								method="POST">

				<div class="row">
					<ValidationProvider ref="form-program-avalible_vo_spo" tag="div" class="col-12 mb-15px position-relative" rules="required" name="Наличие ВПО/СПО" v-slot="{ errors }">

						<div :class="'form-check' + (errors.length ? ' is-invalid' : '')">
							<input type="checkbox"
								   v-model="form['avalible_vo_spo']"
								   id="form-program-field-avalible_vo_spo"
								   class="form-check-input" />
							<label for="form-program-field-avalible_vo_spo" class="form-label">Есть высшее или среднее профессиональном образовании / Сейчас получаю</label>
						</div>
						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-avalible_vo_spo_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider ref="form-program-surname" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required" name="Фамилия" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-surname" class="form-label">Фамилия*</label>

						<input type="text"
							   class="form-control"
							   :class="errors.length ? 'is-invalid' : ''"
							   placeholder="Введите фамилию"
							   id="form-program-field-surname"
							   v-model="form['surname']" />

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-surname_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider ref="form-program-name" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required" name="Имя" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-name" class="form-label">Имя*</label>

						<input type="text"
							   class="form-control"
							   :class="errors.length ? 'is-invalid' : ''"
							   placeholder="Введите имя"
							   id="form-program-field-name"
							   v-model="form['name']" />

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-name_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider ref="form-program-patronymic" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required" name="Отчество" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-patronymic" class="form-label">Отчество*</label>

						<input type="text"
							   class="form-control"
							   :class="errors.length ? 'is-invalid' : ''"
							   placeholder="Введите отчество"
							   id="form-program-field-patronymic"
							   v-model="form['patronymic']" />

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-patronymic_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider ref="form-program-snils" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required|snils" name="СНИЛС" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-snils" class="form-label">СНИЛС*</label>

						<masked-input type="text"
									  class="form-control"
									  :class="errors.length ? 'is-invalid' : ''"
									  placeholder="___-___-___-__"
									  name="form-program-field-snils"
									  id="form-program-field-snils"
									  pattern="111-111-111-11"
									  v-model="form['snils']">

						</masked-input>

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-snils_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider ref="form-program-email" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required|email" name="Эл. адрес" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-email" class="form-label">Эл. адрес*</label>

						<input type="text"
							   class="form-control"
							   :class="errors.length ? 'is-invalid' : ''"
							   :placeholder="'example@mail.ru'"
							   :name="'form-program-field-email'"
							   id="form-program-field-email"
							   v-model="form['email']" />

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-email_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider ref="form-program-phone" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required|phone" name="Телефон" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-phone" class="form-label">Телефон*</label>

						<masked-input type="text"
									  class="form-control"
									  :class="errors.length ? 'is-invalid' : ''"
									  :placeholder="'+7 (999) 999-99-99'"
									  :name="'form-program-field-phone'"
									  id="form-program-field-phone"
									  :pattern="'+7 (111) 111-11-11'"
									  v-model="form['phone']">

						</masked-input>

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-phone_err'">{{err}}</span>
						</div>

					</ValidationProvider>
				</div>

				<template v-if="form.blocks && form.blocks.length">
					<div class="fs-18px fw-600 mb-10px mt-10px">Состав программы:</div>
					<div class="d-flex flex-wrap gap-5">
						<div v-for="block in form.blocks" :key="`block_${block.id}`" v-if="block.blocks_program.length">
							<div class="fs-16px fw-500 mb-12px">{{block.name}}</div>
							<div v-for="item in block.blocks_program" :key="`block_${block.id}_${item.id}`" class="mb-8px">
								<div class="form-check">
									<input type="checkbox" v-model="item.checked" :disabled="block.type === 'obz' || item.type === 'obz'" class="form-check-input form-check-input-bordered" />
									<label class="form-label">{{item.name}}</label>
								</div>
							</div>
						</div>
					</div>
					<div class="fs-18px fw-600 mb-8px mt-16px">Стоимость:</div>
					<div class="fs-16px">{{form.price ? $formatPrice(form.price) : 0}} Р</div>
				</template>

				<button type="submit" class="btn btn-primary px-45px py-10px mt-30px w-200px">Сохранить</button>

			</ValidationObserver>
		</div>
	</div>
</template>

<script>
  import uniqid from 'uniqid'
  import axios from 'axios'
  import appOptions from '../../config/options'

  export default {
    name: 'OrderAddListener',
    data() {
      return {
        program: null,
        form: {
          avalible_vo_spo: false,
          surname: '',
          name: '',
          patronymic: '',
          phone: '',
          email: '',
          snils: '',
          blocks: [],
          price: 0,
        },
      }
    },
    watch: {
      program(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.setProgramBlocksToForm()
        }
      },
    },
    computed: {
      token() {
        return this.$store.getters['auth/token']
      },
      idBid() {
        return this.$route.query?.ID_BID
      },
      idProgram() {
        return this.$route.query?.ID_PROGRAM
      },
      idListener() {
        return this.$route.query?.ID_LISTENER
      },
    },
    created() {
      console.log(this.$route)
      if (!this.program) {
        this.getProgram()
      }
      if (this.idListener) {
        this.getListener()
      }
    },
    methods: {
      hasHistory() {
        return window.history.length > 2
      },
      getListener() {
        axios({
          url: appOptions.api + '/orders/bids/getListenerById/',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`,
          },
          data: {
            listener_id: this.idListener,
            bid_id: this.idBid,
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res.request?.status === 401) {
              this.$store.dispatch('auth/logout')
              this.$router.push({ path: '/login' })
            } else {
              this.form = {
                bid_id: this.idBid,
                listener_id: res.data?.config_listener?.listener_id,
                avalible_vo_spo: res.data?.user?.avalible_vo_spo,
                surname: res.data?.user?.surname,
                name: res.data?.user?.name,
                patronymic: res.data?.user?.patronymic,
                phone: res.data?.user?.phone,
                email: res.data?.user?.email,
                snils: res.data?.user?.snils,
				blocks: this.form.blocks,
				price: this.form.price,
              }
            }
          })
      },
      getProgram() {
        axios({
          url: appOptions.api + '/orders/bids/getProgramById/',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`,
          },
          data: {
            program_id: this.idProgram,
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res.request?.status === 401) {
              this.$store.dispatch('auth/logout')
              this.$router.push({ path: '/login' })
            } else {
              this.program = res.data
            }
          })
      },
      calcPrice() {
        let price = 0
        for (let j = 0; j < this.form.blocks.length; j++) {
          for (let k = 0; k < this.form.blocks[j].blocks_program.length; k++) {
            if (this.form.blocks[j].blocks_program[k].checked) {
              price += Number(this.form.blocks[j].blocks_program[k].price)
            }
          }
        }
        this.form.price = price
      },
      setProgramBlocksToForm() {
        console.log(this.program)
        if (!this.program) return
        this.form.blocks = this.program.groupProgram.map(item => {
          return {
            ...item,
            blocks_program: item.blocks_program.map(block => {
              return {
                ...block,
                checked: true,
              }
            }),
          }
        })
        this.calcPrice()
      },
      async checkSubmit() {
        const isValid = await this.$refs['observer-form-listener'].validate()
        if (isValid) {
          let data = {
            bid_id: this.idBid,
            program_id: this.idProgram,
            group_program_id: [...new Set(this.form.blocks.map(block => block.blocks_program.filter(program => program.checked).map(program => program.pivot?.group_program_id)).flat())],
            surname: this.form.surname,
            name: this.form.name,
            patronymic: this.form.patronymic,
            email: this.form.email,
            phone: this.form.phone,
            snils: this.form.snils,
            avalible_vo_spo: this.form.avalible_vo_spo,
          }

          if (this.idListener) {
            data.listener_id = this.idListener
		  }

          axios({
            url: appOptions.api + (this.idListener ? '/orders/bids/updateListener' : '/orders/bids/createListener/'),
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${this.token}`,
            },
            data: data,
          })
            .then(res => res.data)
            .then(res => {
              if (res) {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: this.idListener ? 'Слушатель обновлен' : `Слушатель создан!`,
                  type: 'success',
                })
                this.$store.dispatch('orders/fetchOrderItem', this.$route.params.id)
                this.$router.push({ path: `/orders/${this.$route.params.id}/` })
              }
            })
        }

      },
    },
  }
</script>
