<template>
	<div>
		<div class="mw-1200px">
			<!-- BEGIN page-header -->
			<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
				<h1 class="page-header">Проверьте правильность заполненных данных</h1>
			</div>
			<!-- END page-header -->
			<ValidationObserver tag="form"
								@submit.prevent="checkSubmit"
								ref="observer-form"
								v-slot="{ invalid }"
								method="POST">

				<div class="row">
					<ValidationProvider ref="form-program-surname" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required" name="Фамилия" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-surname" class="form-label">Фамилия*</label>

						<input type="text"
							   class="form-control"
							   :class="errors.length ? 'is-invalid' : ''"
							   placeholder="Введите фамилию"
							   id="form-program-field-surname"
							   v-model="form['surname']" />

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-surname_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider ref="form-program-name" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required" name="Имя" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-name" class="form-label">Имя*</label>

						<input type="text"
							   class="form-control"
							   :class="errors.length ? 'is-invalid' : ''"
							   placeholder="Введите имя"
							   id="form-program-field-name"
							   v-model="form['name']" />

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-name_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider ref="form-program-patronymic" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required" name="Отчество" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-patronymic" class="form-label">Отчество*</label>

						<input type="text"
							   class="form-control"
							   :class="errors.length ? 'is-invalid' : ''"
							   placeholder="Введите отчество"
							   id="form-program-field-patronymic"
							   v-model="form['patronymic']" />

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-patronymic_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider ref="form-program-snils" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required|snils" name="СНИЛС" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-snils" class="form-label">СНИЛС*</label>

						<masked-input type="text"
									  class="form-control"
									  :class="errors.length ? 'is-invalid' : ''"
									  placeholder="___-___-___-__"
									  name="form-program-field-snils"
									  id="form-program-field-snils"
									  pattern="111-111-111-11"
									  v-model="form['snils']">

						</masked-input>

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-snils_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider ref="form-program-email" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required|email" name="Эл. адрес" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-email" class="form-label">Эл. адрес*</label>

						<input type="text"
							   class="form-control"
							   :class="errors.length ? 'is-invalid' : ''"
							   :placeholder="'example@mail.ru'"
							   :name="'form-program-field-email'"
							   id="form-program-field-email"
							   v-model="form['email']" />

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-email_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider ref="form-program-phone" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required|phone" name="Телефон" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-phone" class="form-label">Телефон*</label>

						<masked-input type="text"
									  class="form-control"
									  :class="errors.length ? 'is-invalid' : ''"
									  :placeholder="'+7 (999) 999-99-99'"
									  :name="'form-program-field-phone'"
									  id="form-program-field-phone"
									  :pattern="'+7 (111) 111-11-11'"
									  v-model="form['phone']">

						</masked-input>

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-phone_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider ref="form-program-telegram" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="telegram" name="Телеграм" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-telegram" class="form-label">Телеграм</label>

						<input type="text"
							   class="form-control"
							   :class="errors.length ? 'is-invalid' : ''"
							   :placeholder="''"
							   :name="'form-program-field-telegram'"
							   id="form-program-field-telegram"
							   v-model="form['telegram']" />

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-telegram_err'">{{err}}</span>
						</div>

					</ValidationProvider>
				</div>

				<button type="submit" class="btn btn-primary px-45px py-10px mt-30px w-200px">Подтвердить данные</button>

			</ValidationObserver>
		</div>
	</div>
</template>

<script>
  import axios from 'axios'
  import appOptions from '../../config/options'

  export default {
    name: 'CFQuestionnaire',
    data() {
      return {
        form: {
          surname: '',
          name: '',
          patronymic: '',
          phone: '',
          email: '',
          snils: '',
		  telegram: ''
        },
      }
    },
    watch: {},
    computed: {
      token() {
        return this.$store.getters['auth/token']
      },
    },
    created() {
    },
    methods: {
      async checkSubmit() {
        const isValid = await this.$refs['observer-form'].validate()
        if (isValid) {
          let data = this.form

          axios({
            url: appOptions.api + '',
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${this.token}`,
            },
            data: data,
          })
            .then(res => res.data)
            .then(res => {
              if (res) {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: `Отправлено на проверку`,
                  type: 'success',
                })
              }
            })
        }

      },
    },
  }
</script>
